// istanbul ignore file
import { Button, Dialog, Heading, Paragraph } from '@hexa-ui/components';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { EMPTY_VALUE } from '../../consts/user';
import useGroupData from '../../hooks/useContext/useGroupData/useGroupData';
import { SearchParamNameTask } from '../../models/activity';
import { GetGroupDetails } from '../../models/group';
import Autocomplete from '../Autocomplete/Autocomplete';
import { ConfigureOperationModalProps } from './ConfigureOperationModal.d';
import Styled from './ConfigureOperationModal.styles';

const ConfigureOperationModal = ({
  isOpen,
  onClose,
  onCancel,
  isToggleEnabled,
}: ConfigureOperationModalProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const [searchParams, setSearch] = useSearchParams();
  const setSearchParams = useCallback(
    (params: Parameters<typeof setSearch>[0]) => setSearch(params, { replace: true }),
    [setSearch]
  );

  const { groupData, groups, handleChangeUserGroupInfo, handleSelectFirstLeafFromNode } =
    useGroupData();
  const { organization, seller } = groupData;

  const [selectedOrganization, setSelectedOrganization] = useState(organization);
  const [selectedSeller, setSelectedSeller] = useState(seller);
  const queryTaskGroupId = searchParams.get(SearchParamNameTask.TaskGroupId);

  const onConfirmModal = () => {
    handleChangeUserGroupInfo({
      organization: selectedOrganization,
      seller: selectedSeller,
    });
    if (queryTaskGroupId) {
      searchParams.delete(SearchParamNameTask.TaskGroupId);
      setSearchParams(searchParams);
    }
    onClose();
  };

  const resetSelectValues = () => {
    setSelectedOrganization(organization);
    setSelectedSeller(seller);
  };

  const hasNoOperationsAvailable = !groups.length;

  const isSaveButtonDisable = useMemo(() => {
    if (isToggleEnabled) return !selectedOrganization?.id || hasNoOperationsAvailable;
    else
      return (
        !selectedOrganization?.id ||
        (selectedOrganization.childGroups.length && !selectedSeller?.id) ||
        hasNoOperationsAvailable
      );
  }, [isToggleEnabled, selectedOrganization, selectedSeller, hasNoOperationsAvailable]);

  const labels: { title: string; placeholder: string; subTitle: string; optional?: string } =
    useMemo(() => {
      if (isToggleEnabled)
        return {
          title: formatMessage({ id: 'configureOperationModal.titleSellerViewInput' }),
          subTitle: formatMessage({ id: 'configureOperationModal.subTitleSellerViewInput' }),
          placeholder: formatMessage({ id: 'configureOperationModal.sellerViewPlaceholder' }),
          optional: formatMessage({ id: 'configureOperationModal.optionalText' }),
        };

      return {
        title: formatMessage({ id: 'configureOperationModal.titleSellerInput' }),
        subTitle: formatMessage({ id: 'configureOperationModal.subTitleSellerInput' }),
        placeholder: formatMessage({ id: 'configureOperationModal.sellerPlaceholder' }),
      };
    }, [formatMessage, isToggleEnabled]);

  const sellerValuesWithEmpty: GetGroupDetails[] = useMemo(() => {
    if (isToggleEnabled) return [EMPTY_VALUE, ...selectedOrganization.childGroups];
    else return [...selectedOrganization.childGroups];
  }, [selectedOrganization, isToggleEnabled]);

  useEffectOnce(() => {
    const hasSellerAvailable = !!organization.childGroups.length;
    const hasNoSellerSelected = !selectedSeller?.id;
    if (hasSellerAvailable && hasNoSellerSelected && !isToggleEnabled)
      handleSelectFirstLeafFromNode();
  });

  return (
    <div>
      {isOpen && (
        <Dialog.Root
          title={
            <Heading data-testid="configureOperationModal" size="H2">
              {formatMessage({ id: 'configureOperationModal.title' })}
            </Heading>
          }
          onClose={() => {
            resetSelectValues();
            onClose();
          }}
          contentCss={{ overflowY: 'visible' }}
          open={isOpen}
          closeButton={true}
          actions={
            <Styled.WrapperDialogButtons>
              <Dialog.Close>
                <Button
                  variant="secondary"
                  onClick={() => {
                    resetSelectValues();
                    onCancel();
                  }}
                >
                  {formatMessage({ id: 'configureOperationModal.cancel' })}
                </Button>
              </Dialog.Close>
              <Dialog.Close>
                <Button
                  disabled={isSaveButtonDisable ?? hasNoOperationsAvailable}
                  onClick={onConfirmModal}
                >
                  {formatMessage({ id: 'configureOperationModal.confirm' })}
                </Button>
              </Dialog.Close>
            </Styled.WrapperDialogButtons>
          }
        >
          <Styled.BodyContainer>
            <Paragraph css={{ marginBottom: '26px' }}>
              {formatMessage({ id: 'configureOperationModal.description' })}
            </Paragraph>

            <Styled.InputContainer hasSellers={!!organization.childGroups.length}>
              <Autocomplete
                title={formatMessage({ id: 'configureOperationModal.titleOrganizationInput' })}
                hint={formatMessage({ id: 'configureOperationModal.subTitleOrganizationInput' })}
                componentSize="fill"
                placeholder={formatMessage({
                  id: 'configureOperationModal.organizationPlaceholder',
                })}
                keyLabel="name"
                keyValue="id"
                value={selectedOrganization}
                data={groups}
                isLoading={false}
                isDisabled={hasNoOperationsAvailable}
                onSelect={(item) => {
                  const { id } = item;

                  const selectedItem = groups.find((item) => item.id === id);
                  selectedOrganization.id !== selectedItem.id && setSelectedSeller(EMPTY_VALUE);
                  setSelectedOrganization(selectedItem);

                  if (selectedItem?.childGroupIds?.length === 0) {
                    setSelectedSeller(EMPTY_VALUE);
                  }
                }}
                onFinishScroll={null}
                isSearchable={false}
              />

              {!!selectedOrganization.childGroups?.length && (
                <Autocomplete
                  title={labels.title}
                  hint={labels.subTitle}
                  optional={labels.optional}
                  componentSize="fill"
                  placeholder={labels.placeholder}
                  keyLabel="name"
                  keyValue="id"
                  value={selectedSeller?.id ? selectedSeller : null}
                  data={sellerValuesWithEmpty}
                  isLoading={false}
                  onSelect={(item) => {
                    const { id } = item;
                    const selectedSeller = selectedOrganization.childGroups.find(
                      (item) => item.id === id
                    );
                    setSelectedSeller(selectedSeller ?? EMPTY_VALUE);
                  }}
                  onFinishScroll={null}
                  isSearchable={false}
                />
              )}
            </Styled.InputContainer>
          </Styled.BodyContainer>
        </Dialog.Root>
      )}
    </div>
  );
};

export default ConfigureOperationModal;
