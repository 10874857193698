import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PhotoIcon from '../../assets/icons/photo.svg';
import SurveyIcon from '../../assets/icons/survey.svg';
import SimpleIcon from '../../assets/icons/task.svg';
import {
  ActivityCategory,
  ActivityState,
  ActivityType,
  ActivityTypeName,
  AddMethod,
  Feature,
  FeatureMultiselectMetadada,
  SubTypeName,
} from '../../models/activity';

const initialState = {
  availableActivityTypes: [
    {
      activityTypeId: null,
      name: ActivityTypeName.Simple,
      icon: SimpleIcon,
    },
    {
      activityTypeId: null,
      name: ActivityTypeName.Survey,
      icon: SurveyIcon,
    },
    {
      activityTypeId: null,
      name: ActivityTypeName.Photo,
      icon: PhotoIcon,
    },
  ],
  availableActivityCategories: undefined,
  availableSubTypes: [
    {
      id: SubTypeName.Challenge,
      addMethods: [AddMethod.Manual],
    },
    {
      id: SubTypeName.Combo,
      addMethods: [AddMethod.Manual, AddMethod.Csv],
    },
    {
      id: SubTypeName.Coverage,
      addMethods: [AddMethod.Manual, AddMethod.Csv],
    },
    {
      id: SubTypeName.Volume,
      addMethods: [AddMethod.Manual, AddMethod.Csv],
    },
  ],
  featureList: [],
  featureMultiselectMetadata: [],
  groupedFeatureList: [],
} as ActivityState;

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setAvailableActivityTypes: (state: ActivityState, action: PayloadAction<ActivityType[]>) => {
      state.availableActivityTypes = action.payload;
    },
    resetActivityTypes: (state) => {
      state.availableActivityTypes = initialState.availableActivityTypes;
    },
    setAvailableActivityCategories: (
      state: ActivityState,
      action: PayloadAction<ActivityCategory[]>
    ) => {
      state.availableActivityCategories = action.payload;
    },
    setActivityFeatureList: (state: ActivityState, action: PayloadAction<Feature[]>) => {
      state.featureList = action.payload;
    },
    setFeatureMultiselectMetadata: (
      state: ActivityState,
      action: PayloadAction<FeatureMultiselectMetadada[]>
    ) => {
      state.featureMultiselectMetadata = action.payload;
    },
    setActivityGroupedFeatureList: (state: ActivityState, action: PayloadAction<Feature[]>) => {
      state.groupedFeatureList = action.payload;
    },
  },
});

export const {
  setAvailableActivityTypes,
  setAvailableActivityCategories,
  resetActivityTypes,
  setActivityFeatureList,
  setFeatureMultiselectMetadata,
  setActivityGroupedFeatureList,
} = activitySlice.actions;

export default activitySlice.reducer;
